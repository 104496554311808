import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | піцерія Bella
			</title>
			<meta name={"description"} content={"Твій шматочок неба!"} />
			<meta property={"og:title"} content={"Головна | піцерія Bella"} />
			<meta property={"og:description"} content={"Твій шматочок неба!"} />
			<meta property={"og:image"} content={"https://povixolar.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://povixolar.com/img/1404945.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://povixolar.com/img/1404945.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://povixolar.com/img/1404945.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://povixolar.com/img/1404945.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://povixolar.com/img/1404945.png"} />
			<meta name={"msapplication-TileImage"} content={"https://povixolar.com/img/1404945.png"} />
			<meta name={"msapplication-TileColor"} content={"https://povixolar.com/img/1404945.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://povixolar.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					піцерія Bella
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Ласкаво просимо до піцерії Bella Pizzeria, де кожен шматочок переносить вас у подорож до самого серця Італії. Ми прагнемо створювати найавтентичніші та найсмачніші піци, використовуючи найсвіжіші інгредієнти та традиційні рецепти. Затишна та гостинна атмосфера робить Bella Pizzeria ідеальним місцем, щоб насолодитися трапезою з родиною та друзями.
				</Text>
				<Link
					href="/contacts"
					padding="12px 24px 12px 24px"
					color="--dark"
					background="--color-secondary"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 0px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-orange"
					sm-margin="0px 0px 0px 0px"
					sm-text-align="center"
					hover-transition="background-color 0.2s ease-in-out 0s"
				>
					Знайти Нас
				</Link>
			</Box>
		</Section>
		<Section padding="50px 0 30px 0" lg-padding="80px 0 90px 0" quarkly-title="Stages/Steps-3">
			<Text
				margin="0px 0px 80px 0px"
				font="--headline2"
				color="--darkL1"
				border-color="--color-dark"
				text-align="center"
				lg-margin="0px 0px 50px 0px"
			>
				Чому варто обрати піцерію Bella?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap={0}
				padding="0px 50px 0px 50px"
				lg-padding="0px 0 0px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Автентичний смак
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							Наші рецепти натхненні традиційною італійською кухнею, що гарантує справжній смак у кожному шматочку.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 34px 25px"
					sm-padding="0px 0 34px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Свіжі інгредієнти
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							Ми використовуємо тільки найсвіжіші, високоякісні інгредієнти для створення наших піц, роблячи їх ароматними та корисними.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					lg-padding="0px 25px 26px 25px"
					flex-direction="column"
					align-items="center"
					padding="0px 30px 30px 30px"
					md-padding="0px 25px 0 25px"
					sm-padding="0px 0 0 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 0">
						<Text
							margin="0px 0px 15px 0px"
							border-color="--color-light"
							color="--darkL1"
							font="normal 500 22px/1.2 --fontFamily-sans"
							text-align="center"
						>
							Затишна атмосфера
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							font="normal 400 16px/1.5 --fontFamily-sansHelvetica"
							color="#65696d"
							text-align="center"
							border-color="#7a7c7f"
						>
							Наша піцерія пропонує теплу та гостинну атмосферу, яка ідеально підходить для сімейних обідів, побачень або випадкових зустрічей.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="64px 0 64px 0" md-padding="36px 0 36px 0" lg-padding="48px 0 48px 0" quarkly-title="Images-20">
			<Override slot="SectionContent" max-width="960px" />
			<Box
				quarkly-title="Card"
				justify-content="space-between"
				sm-flex-direction="column"
				position="relative"
				lg-margin="0px 0px 64px 0px"
				sm-align-items="center"
				display="flex"
				align-items="center"
				margin="0px 0px 96px 0px"
			>
				<Image
					src="https://povixolar.com/img/2.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
				/>
				<Text
					sm-margin="0px 0px 0px 0px"
					sm-text-align="left"
					margin="0px 0px 0px 8%"
					max-width="450px"
					md-margin="0px 0px 0px 24px"
					font="--lead"
				>
					У Bella Pizzeria ми віримо, що чудова піца починається з чудових інгредієнтів. Саме тому ми обираємо лише найкращі помідори, сири та начинки для наших пирогів. Тісто готується щодня, замішується вручну і випікається до досконалості в наших кам'яних печах. Якщо ви віддаєте перевагу класичній "Маргариті", ситним м'ясним стравам або вишуканим вегетаріанським стравам, у нас знайдеться щось на будь-який смак.
				</Text>
			</Box>
			<Box
				align-items="center"
				sm-flex-direction="column-reverse"
				sm-align-items="center"
				quarkly-title="Card"
				display="flex"
				justify-content="space-between"
			>
				<Box sm-margin="0px 0px 0px 0px" max-width="450px" margin="0px 8% 0px 0px" md-margin="0px 24px 0px 0px">
					<Text font="--lead" sm-text-align="left">
					Приєднуйтесь до нас у піцерії "Белла" та відчуйте шматочок раю. Насолоджуйтесь смаком, атмосферою і зробіть кожну трапезу незабутньою!
					</Text>
					<Button
						type="link"
						href="/contacts"
						margin="20px 0px 0px 0px"
						padding="15px 45px 15px 45px"
						text-decoration-line="initial"
						color="--darkL2"
						font="--lead"
					>
						Контакти
					</Button>
				</Box>
				<Image
					md-width="50%"
					sm-margin="0px 0px 24px 0px"
					align-self="flex-end"
					src="https://povixolar.com/img/7.jpg"
					object-fit="cover"
					width="45%"
					flex="0 0 auto"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});